<template>
  <UpdateAvailable />
  <router-view></router-view>
</template>

<script>
import {mapActions} from 'vuex';
import UpdateAvailable from "@/components/UpdateAvailable.vue";

function verifyCookies() {
  if (localStorage['sessionID'] === undefined) {
    localStorage['sessionID'] = Math.floor(Math.random() * 1000000000);
  }

  if (localStorage['sync'] === undefined) {
    localStorage['sync'] = 0;
  }

  if (localStorage['socketStatus'] === undefined) {
    localStorage['socketStatus'] = '';
  }
}

export default {
  name: 'App',
  components: {UpdateAvailable},
  methods: {
    ...mapActions(['initializeSocket'])
  },
  created() {
    verifyCookies();
    const port = process.env.VUE_APP_SOCKET_PORT;
    this.initializeSocket(port);
  }
}
</script>
