<template>
  <div v-if="updateVisible" class="status-container">
    <button
        class="status-button"
        @click="refreshApp"
    >
      Update Available
    </button>
  </div>
</template>

<script>
export default {
  name: 'UpdateAvailable',
  data() {
    return {
      updateVisible: false,
      registration: null,
      refreshing: false
    }
  },
  methods: {
    refreshApp() {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateVisible = true
    }
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
  }
}
</script>

<style scoped>
.status-button {
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: #d17008;
  padding:8px;
  color:white;
}
.status-button:hover {
  background-color: #c66b08;
}

.status-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>