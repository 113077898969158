import { createApp } from 'vue';
import App from './App.vue';

import './assets/main.css';

import Toaster from '@meforma/vue-toaster';
import router from './router';
import store from './store';
import './registerServiceWorker';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Toaster)
app.mount('#app')
