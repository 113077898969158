import { createStore } from 'vuex';
import { mySocket } from '@/assets/js/socket';

export default createStore({
	state: {
		socket: null,
		connectionStatus: false,
		authPending: false,
		reconnectAttempt: 0,
		reconnectDelay: 5000
	},
	mutations: {
		setSocket(state, socket) {
			state.socket = socket;
		},
		setConnectionStatus(state, status) {
			state.connectionStatus = status;
		},
		setAuthPending(state, status) {
			state.authPending = status;
		},
		incrementReconnectAttempt(state) {
			state.reconnectAttempt++;
		},
		resetReconnectAttempt(state) {
			state.reconnectAttempt = 0;
		}
	},
	actions: {
		async initializeSocket({ commit, state, dispatch }, port) {
			// Close existing socket if it exists
			if (state.socket) {
				state.socket.close();
				commit('setSocket', null);
			}

			try {
				const socket = mySocket(port);
				let connectionTimeout;

				return new Promise((resolve, reject) => {
					socket.onopen = () => {
						clearTimeout(connectionTimeout);
						commit('setSocket', socket);
						commit('setConnectionStatus', true);
						commit('resetReconnectAttempt');
						resolve(socket);
					};

					socket.onclose = (event) => {
						clearTimeout(connectionTimeout);
						commit('setConnectionStatus', false);
						console.log(`Socket closed: code=${event.code}, reason=${event.reason}`);
						dispatch('handleSocketClose');
					};

					socket.onerror = (error) => {
						clearTimeout(connectionTimeout);
						console.error('Socket error:', error);
						commit('setConnectionStatus', false);
						reject(error);
					};

					// Set a timeout for the connection attempt
					connectionTimeout = setTimeout(() => {
						if (socket.readyState !== WebSocket.OPEN) {
							socket.close();
							reject(new Error('WebSocket connection timeout'));
						}
					}, 5000);
				});
			} catch (error) {
				console.error('Socket initialization error:', error);
				throw error;
			}
		},

		async handleSocketClose({ state, dispatch, commit }) {
			// Increase the reconnectAttempt count
			commit('incrementReconnectAttempt');

			const delay = state.reconnectDelay;
			console.log(
				`Attempting reconnection #${state.reconnectAttempt} in ${delay}ms`
			);

			// Delay before trying to reconnect again
			await new Promise(resolve => setTimeout(resolve, delay));

			try {
				await dispatch('initializeSocket', process.env.VUE_APP_SOCKET_PORT);
				console.log('Reconnection successful');
			} catch (error) {
				console.error('Reconnection failed:', error);
				// The next onclose event will trigger another attempt
			}
		},

		sendMessage({ state, dispatch }, payload) {
			if (state.socket?.readyState === WebSocket.OPEN) {
				state.socket.send(JSON.stringify(payload));
				return true;
			} else {
				console.error('Socket is not connected. Attempting to reconnect...');
				dispatch('handleSocketClose');
				return false;
			}
		},

		async authenticate({ state, dispatch, commit }, { route, payload }) {
			try {
				commit('setAuthPending', true);

				if (!state.socket || state.socket.readyState !== WebSocket.OPEN) {
					await dispatch('initializeSocket', process.env.VUE_APP_SOCKET_PORT);
				}

				return dispatch('sendMessage', {
					route,
					...payload
				});
			} finally {
				commit('setAuthPending', false);
			}
		}
	},
	getters: {
		socket: state => state.socket,
		connectionStatus: state => state.connectionStatus,
		isSocketConnected: state => state.socket?.readyState === WebSocket.OPEN,
		isAuthPending: state => state.authPending,
		reconnectAttempt: state => state.reconnectAttempt
	}
});
