<template>
  <div>
    {{ formattedDate }}
  </div>
</template>

<script>
export default {
  props: ['params'],
  computed: {
    formattedDate() {
      if (!this.params.value) return '';

      // If the row has 'fakeDate' set to true, skip rendering
      if (this.params.data && this.params.data.fakeDate) {
        return 'NOT SET';
      }

      let date;
      try {
        // Attempt to parse the date (handles timezone if present)
        date = new Date(this.params.value);
        if (isNaN(date.getTime())) throw new Error('Invalid Date');
      } catch {
        // Fallback to treating it as UTC if parsing fails
        date = new Date(this.params.value + 'T00:00:00Z');
      }

      // Format date as MM-DD-YYYY
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();

      // Check for timezone information
      const hasTimezone = this.params.value.includes('T') && this.params.value.includes(':');

      if (hasTimezone) {
        // Format time as HH:MM:SS AM/PM
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12 || 12; // 0 becomes 12

        return `${month}-${day}-${year} ${String(hours).padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
      } else {
        return `${month}-${day}-${year}`;
      }
    }
  }
};
</script>
